import * as React from "react"
import Helmet from "react-helmet"

import Layout from '../components/layout.js'
import Contact from '../components/contact.js'

const IndexPage = () => {
  return (
    <Layout>

      <Helmet>
        <title>Freelance Designer and Developer Brighton | Billy Dawson</title>
        <meta property="description" content="Here to help, whether you want a new website from the ground up, changes to your current site or a hand with some digital odd jobs." />
      </Helmet>

      <div className="big-header">
        <div className="wrap container">
          <div className="row">
            <div className="col-xs-11 col-sm-8 col-md-6 col-lg-5">
              <p className="h1">Hey! 👋🏻</p>
              <h1>I’m a full stack developer based in Brighton ⛱</h1>
              <p>Currently working on making digital products with a low environmental impact at <a href="https://helloseed.co.uk/">Seed.</a></p>
              <p><a href="/about/">About me</a></p>
            </div>
          </div>
        </div>
      </div>

      {/*
      <div className="section main">
        <div className="wrap container">
          <div className="row title-row">
            <div className="col-xs-12">
              <h2>Projects</h2>
            </div>
          </div>

          <div className="row list-items">

            <div className="col-xs-12 col-md-4">
              <h3>Fiddle & Thorn</h3>
              <p>Low impact niche-website rebuild with super performant front-end.</p>
              <p><a href="/">Find out more</a></p>
            </div>

            <div className="col-xs-12 col-md-4">
              <h3>RollCall</h3>
              <p>Slack based desk booking system for remote-first businesses.</p>
              <p><a href="/">Find out more</a></p>
            </div>

            <div className="col-xs-12 col-md-4">
              <h3>Downtime</h3>
              <p>Get website downtime alerts directly in Slack as it happens.</p>
              <p><a href="/">Find out more</a></p>
            </div>

          </div>
        </div>
      </div>
      */}

      {/*
      <div className="section main">
        <div className="wrap container">

          <div className="row">

            <div className="col-xs-12 col-md-4">
              <h3>Blog</h3>
              <p>Get an idea of what I’m getting stuck into at the minute...</p>
              <p><a href="/">Go to the blog</a></p>
            </div>

            <div className="col-xs-12 col-md-8 blog-list">
              <div className="blog-item">
                <h3>Making a Simple Slack Application</h3>
                <p>Get started with making your first simple Slack app, this should give you a great foundation + understanding of the underlying API.</p>
                <p><a href="/">Read now</a></p>
              </div>
              <div className="blog-item">
                <h3>Optimising Websites for Carbon Impact</h3>
                <p>Understand the route issues with most websites and get a good grasp on what to look for when optimising a website for the environment.</p>
                <p><a href="/">Read now</a></p>
              </div>
              <div className="blog-item">
                <h3>Using WordPress with GatsbyJS</h3>
                <p>Level up your WordPress projects with GatsbyJS and the benefits of Static Site Generation.</p>
                <p><a href="/">Read now</a></p>
              </div>
            </div>

          </div>
        </div>
      </div>
      */}

      <Contact colour="yellow" />

    </Layout>
  )
}

export default IndexPage
